export type IKeyValueData = {
    id: number,
    data_key: string,
    data_value: string,
}

export type IVariableKeyValueData = {
    id: number,
    data_key: string,
    data_value: string,
    nodeId: string,
    meta?: any
}

export enum IHTTPRequestDataTypes {
    NONE = 1,
    FORM = 2,
    JSON = 3,
    // RAW = 4,
    XML = 5
}

export enum LocationInterfaceContinents {
    africa = 0,
    asia = 1,
    middleEast = 2,
    australia = 3,
    europe = 4,
    america = 5,
}

export type IHTTPRequestBodyData = {
    dataType: IHTTPRequestDataTypes,
    form?: IKeyValueData[] | null | undefined,
    JSON?: string | undefined,
    XML?: string | undefined,
    raw?: string | undefined,
}

export type IHTTPRequestData = {
    method: string,
    URL: string,
    headers?: IKeyValueData[] | null | undefined,
    params?: IKeyValueData[] | null | undefined,
    body?: IHTTPRequestBodyData,
    variables?: IVariableKeyValueData[] | null | undefined,
}

export type IFlowItemData = {
    id: number,
    data: IHTTPRequestData
}

export enum monitorTypes {
    https = "https",
    ping = "ping",
    port = "port"
}

export interface MonitorInterface {
    projectId: string,
    monitorId: string,
    monitorType: monitorTypes,
    name: string,
    method: string,
    endpoint: string,
    port: number,
    regionIDList: number[],
    requestPayload: IHTTPRequestData,
    checkInterval: number,
    timeoutInterval: number,
    responseAssert: string,
    expectedHTTPCode: number,
    lastNotification: Date,
    lastError: string | null,
    endpointStatus: number,
    advanced: boolean,
    status: number,
    uptime?: number | string,
}

export interface ProjectInterface {
    _id: string,
    projectId: string,
    userId: string,
    name: string,
    status: number,
    isOwner: boolean,
}

export interface LocationInterface {
    _id: string,
    regionId: number,
    code: string,
    city: string,
    shortCity: string,
    name: string,
    location: number,
    enabled: boolean,
    unavailable: boolean,
}

export type HTTPBreakdownData = {
    averageDNS: number,
    averageDownload: number,
    averageFirstByte: number,
    averageTCP: number,
    averageTotal: number,
    averageWait: number,
    regionId: number,
}

export type SSLCertificateData = {
    issuer: string,
    subject: string,
    valid_from: string | number | Date,
    valid_to: string | number | Date,
}

export type uptimeLogsData = {
    date: string,
    status: number,
    duration: number,
    regionId: number,
    uptime: number,
}

export type IncidentLogData = {
    endpointStatus: UPTIME_LOG_STATUSES
    id: string
    lastError: string | null
    lastNotification: string | null
    lastStatusCode: number | null
    regionId: number
    createdAt: string | Date | undefined | null,
    updatedAt: string | Date | undefined | null
}

export type MonitorResponseData = {
    monitor: MonitorInterface,
    incidentLogs: IncidentLogData[]
    ssl: {
        info: SSLCertificateData | null,
        createdAt: string | Date | undefined | null,
        updatedAt: string | Date | undefined | null,
    }
    logs: {
        lastResponseTime: number,
        averageResponseTime: number,
        uptimeLogs: uptimeLogsData[],
        uptime: number,
    },
}

export enum PLANS_BY_ID {
    free = 0,
    hobby = 1,
    startup = 2,
    business = 3,
}

export type IStripeEventSubCreate = {
    id: string;
    active: boolean;
    startDate: Date;
    trialEnd: Date | undefined,
    endDate: Date;
    cancelAt: Date;
    priceId: string;
}
export type AccountSubscription = {
    id: string,
    active: boolean,
    status: ISubscriptionStatusTypes,
    deletedAt: Date,
    createdAt: Date,
    updatedAt: Date,
    meta: IStripeEventSubCreate
}

export type ACCOUNT_SESSION = {
    country: string,
    email: string,
    emailVerified: boolean,
    limits: IBusinessPlanConfigType,
    plan: number,
    subscription: AccountSubscription | null,
    projects: ProjectInterface[],
    status: DATA_STATUSES,
    token?: string,
    trial: boolean,
    website: string,
    isProjectOwner?: boolean,
}

export const PlansByID = {
    0: "free",
    1: "hobby",
    2: "startup",
    3: "business",
}

export enum DATA_STATUSES {
    inactive = 0,
    active = 1,
    expired = 2,
    suspended = 3,
    terminated = 4,
    banned = 5,
    pending = 6,
}

export const DATA_STATUSES_REVERSE = {
    0: "inactive",
    1: "active",
    2: "expired",
    3: "suspended",
    4: "terminated",
    5: "banned",
    6: "pending",
}

export enum UPTIME_LOG_STATUSES {
    up = 0,
    down = 1,
    paused = 2,
    started = 3,
    stopped = 4,
    restarted = 5,
    resumed = 6,
    expired = 7,
    suspended = 8,
    terminated = 9,
    banned = 10,
    internalError = 11,
    unavailable = 12,
}

export enum AlertChannels {
    webhook = 0,
    appPush = 1,
    webPush = 2,
    telegram = 3,
    whatsapp = 4,
    slack = 5,
    teams = 6,
    pagerDuty = 7,
    opsgenie = 8,
    discord = 9,
    twilio = 10,
    email = 11,
}

export interface IntegrationCredentialsInterface {
    integrationId: string,
    projectId: string,
    userId: string,
    name: string,
    services: string[],
    token?: string | null,
    channel: number,
    isDefault: boolean,
    status: number,
    meta?: Partial<{
        receiverUsersIds: string[],
        slackTeamName: string,
        slackChannelName: string,
        slackMeta: {
            webhook: {
                channel: string;
                channel_id: string;
                configuration_url: string;
                url: string;
            },
            team: {
                id: string;
                name: string;
            }
        }
    }>,
    createdAt?: Date,
}

export enum ServiceTypes {
    flow = 'flow',
    monitor = 'monitor'
}

export type ToggleServiceToIntegration = {
    integrationId: string,
    serviceId: string,
    serviceType: ServiceTypes
}

export enum checkIntervalsEnum {
    "second10" = 10,
    "second20" = 20,
    "second30" = 30,
    "minute1" = 60,
    "minute5" = 300,
    "minute10" = 600,
    "minute30" = 1800,
    "hour1" = 3600,
    "hour12" = 43200,
    "hour24" = 86400,
}

export type IBusinessPlanConfigType = {
    id: PLANS_BY_ID,
    monitor: {
        monitorsLimit: number,
        minMonitorCheckIntervalLimit: checkIntervalsEnum,
    },
    flow: {
        flowsLimit: number,
        flowMaxNodesLimit: number,
        minFlowCheckIntervalLimit: checkIntervalsEnum,
    },
    statusPage: {
        maxStatusPagesLimit: number,
        maxStatusPageSubscribersLimit: number,
    },
    project: {
        maxProjectsLimit: number,
        maxProjectInviteeLimit: number,
    }
    integrations: {
        channel: AlertChannels,
        limit: number,
    }[]
}

export type IProjectUsers = {
    email: string
    role: ProjectUserRoles
    status: DATA_STATUSES
}

export enum ProjectUserRoles {
    owner = 0,
    admin = 1,
    member = 2,
}

export type ISubscriptionStatusTypes =
    | 'active'
    | 'canceled'
    | 'incomplete'
    | 'incomplete_expired'
    | 'past_due'
    | 'paused'
    | 'trialing'
    | 'unpaid';

export enum SubscriptionStatusEnum {
    active = 'active',
    canceled = 'canceled',
    incomplete = 'incomplete',
    incomplete_expired = 'incomplete_expired',
    past_due = 'past_due',
    paused = 'paused',
    trialing = 'trialing',
    unpaid = 'unpaid',
}